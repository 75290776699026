import React from 'react';

const KeyRemortgageFAQ = () => ([
    {
      title: 'What is remortgaging?',
      description: (
        <>
          Remortgaging is the process of moving from your current mortgage deal to a new deal with the same lender or to a new lender.
        </>
      ),
    },
    {
      title: 'How do I know when it\'s the right time to remortgage?',
      description: (
        <>
          The best time to remortgage is when your current deal is ending.<br/><br/>
          Usually, your current lender will get in touch and let you know you can remortgage. It's a good idea to start looking at other mortgage deals about 6 months before your current deal expires so you aren't moved to your lender's standard variable rate (SVR) which is more expensive.
        </>
      ),
    },
    {
      title: 'Are there fees when remortgaging?',
      description: (
        <>
          When you remortgage to a new lender, there can be fees such as arrangement fees, valuation fees, legal fees, and potential exit fees from your current lender. Be sure to factor in these costs when comparing new deals.<br/><br/>
          It's also worth checking with your existing lender if there are any early repayment charges if you're considering moving to a new mortgage deal early.<br /><br />
          Although mortgage product transfers tend to not have any fees associated, it's best to check with a mortgage broker or the legal documents to make sure there aren't any hidden fees.
        </>
      ),
    },
    {
      title: 'What are mortgage overpayments?',
      description: (
        <>
          A mortgage overpayment is an additional payment on top of your regular monthly payments set out in your mortgage terms. Usually, you can make 10% of the mortgage amount as an overpayment.
        </>
      ),
    },
    {
      title: 'What are the benefits of making an overpayment?',
      description: (
        <>
          The key benefit of making a mortgage overpayment is that you could save money and become mortgage-free, faster. <br /><br />
          The benefit of making an overpayment is that you will be directly reducing the mortgage balance and retaining more equity in your property, which results in you taking less time to repay the whole mortgage debt.
        </>
      ),
    },
  ]);

export default KeyRemortgageFAQ;
